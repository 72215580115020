import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { configureStore } from "./redux/Store";
import "./data";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import Spinner from "./views/spinner/Spinner";
import { lazy } from "react";
import { apiGetSession } from "./apis";
import * as Sentry from "@sentry/react";


const initalization = async () => {

  Sentry.init({
    dsn: "https://f41989632762c60a2f49b629c0078996@o4505270315778048.ingest.us.sentry.io/4507386696302592", 
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


  try {
    let result = await apiGetSession();
    localStorage.setItem("session", result.init);

    const App = lazy(() => import("./App"));
    ReactDOM.render(
      <Provider store={configureStore()}>
        <Suspense fallback={<Spinner />}>
          <HashRouter>
            <App />
          </HashRouter>
        </Suspense>
      </Provider>,
      document.getElementById("root")
    );
  } catch (error) {
    //console.log("Initialization", error);
  }
};

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initalization();
