import { urlEndPoint } from '../Config';
import {
  callApi,
  callApiInit,
  callApiLogin,
  getTokenView,
  encryptedaes256cbc,
  descryptedaes256cbc,
  encryptedaes256cbcTextPlain,
  callSendFornData,
} from './utils';

function getToken() {
  let token = '';
  try {
    const localToken = getTokenView(localStorage.getItem('session'));
    if (process.env.REACT_APP_AAA === 'true') {
      token = descryptedaes256cbc(localStorage.getItem('aet'), localToken);
    } else {
      token = JSON.parse(localStorage.getItem('aet'));
    }

    return token.access_token;
  } catch (error) {
    // console.log("EREA", error);
  }
}
//VERIFY TOKEN

export const apiGetVerifyToken = async () => {
  try {
    const url = `${urlEndPoint}/api/v3/users/verify`;
    // console.log(url, getToken());
    const json = await callApi(url, 'GET', getToken(), null);
    // console.log('HSON', json);
    return { code: 200 };
  } catch (error) {
    return { code: 401 };
  }
};

//SESSION TOKEN
export const apiGetSession = async () => {
  const url = `${urlEndPoint}/api/v3/auth/init`;
  const json = await callApiInit(url, 'GET', null, null);
  return json;
};

//LOGIN
export const apiLogin = async (requestData) => {
  const url = `${urlEndPoint}/api/v3/auth/signin`;
  const json = await callApiLogin(url, 'POST', null, requestData);
  return json;
};

//DASHBOARD
export const apiGetStatServiceMonthAmount = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/month/amount';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceDashboard = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/dashboard';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceVender = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/service/vender/' + year;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatGerence = async (typeClient) => {
  const url = urlEndPoint + '/api/v3/report/stat/gerencia/' + typeClient;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatVent = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/vent/' + year;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatVentLineal = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/vender/lineal';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceAdditionalVender = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/service/additional/' + year;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceMonthCount = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/month/count';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllTransactionLast = async () => {
  const url = urlEndPoint + '/api/v3/transaction/last';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllMaintenance = async (year, month, limit) => {
  const url = `${urlEndPoint}/api/v3/maintenance/all/${year}/${month}/${limit}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//BOOKING
export const apiGetMaintenceBooking = async (status) => {
  const url = `${urlEndPoint}/api/v3/maintenance/booking/${status}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetMaintenceBusinessBooking = async (status) => {
  const url = `${urlEndPoint}/api/v3/maintenance/business/booking/${status}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetMaintenceBookingStatus = async (status) => {
  const url = `${urlEndPoint}/api/v3/maintenance/filter/status/${status}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetMaintenceBookingStatusNoPaid = async (type) => {
  const url = `${urlEndPoint}/api/v3/maintenance/filter/nopaid/${type}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetMaintenceBookingID = async (id) => {
  const url = `${urlEndPoint}/api/v3/maintenance/detail/${id}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getGetMaintenceAdicional = async (id) => {
  const url = `${urlEndPoint}/api/v3/maintenance/adicional/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const updateUserMaintenance = async (newuser, maintenance) => {
  const url = `${urlEndPoint}/api/v3/maintenance/update/client`;
  let body = { iduser: newuser, maintanceId: maintenance };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updatePayMaintenance = async (body) => {




  const url = `${urlEndPoint}/api/v3/maintenance/update/pay`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updateVendorMaintenance = async (
  newVendorId,
  maintenanceserviceid,
  maintanceId
) => {
  const url = `${urlEndPoint}/api/v3/maintenance/vendor/${maintanceId}`;
  let body = { maintanceService: maintenanceserviceid, vendor: newVendorId };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updateAsesorMaintenance = async (advisersId, maintanceId) => {
  const url = `${urlEndPoint}/api/v3/maintenance/adviser/${maintanceId}`;
  let body = { advisers: advisersId };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const createNewAdditional = async (
  maintanceId,
  provider,
  price,
  cost,
  vender,
  description
) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/additional/${maintanceId}`;
  let body = {
    ProviderServiceId: provider,
    price: price,
    cost: cost,
    sellerId: vender,
    description: description,
  };
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const createNewMaintenance = async (
  vender,
  store,
  vechiculo,
  serviceId,
  price,
  description,
  duration,
  newclienteid,
  provider,
  date,
  times,
  scheduler,
  providerService
) => {
  const url = `${urlEndPoint}/api/v3/maintenance`;
  let body = {
    adminId: vender,
    storeId: store,
    vehicleId: vechiculo,
    serviceId: serviceId,
    price: price,
    scheduleDate: scheduler,
    displayTime: times,
    displayDate: date,
    diagnostic: description,
    duration: duration,
    clientId: newclienteid,
    providerId: provider,
    providerService: providerService,
  };
  
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const updateServiceMaintenance = async (
  newstatus,
  date,
  dateDeadline,
  times,
  scheduler,
  maintenance
) => {
  const url = `${urlEndPoint}/api/v3/maintenance/update/maintenance`;
  let body = {
    newstatus: newstatus,
    displayDate: date,
    dateDeadline: dateDeadline,
    displayTime: times,
    scheduler: scheduler,
    maintanceId: maintenance,
  };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updateServiceStatusMaintenance = async (
  newstatus,
  maintenance,
  userSAP
) => {
  const url = `${urlEndPoint}/api/v3/maintenance/update/status/maintenance`;
  let body = {
    newstatus: newstatus,
    maintanceId: maintenance,
    userSAP: userSAP,
  };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updateServiceHijoMaintenance = async (
  newstatus,
  maintenanceserviceid
) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/${maintenanceserviceid}`;
  let body = {
    newstatus: newstatus,
  };
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const updateMantenanceStore = async (newstoreId, newtranspId, maintanceId, body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/store/${maintanceId}/${newstoreId}/${newtranspId}`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

//VENDER
export const apiGetAllServiceSales = async (
  limit,
  padre,
  hijo,
  plate,
  rut,
  type
) => {
  const url = urlEndPoint + '/api/v3/maintenance/service/all/' + limit;
  let search = {
    padre: padre,
    hijo: hijo,
    plate: plate,
    rut: rut,
    type: type,
  };
  const json = await callApi(url, 'POST', getToken(), search);
  return json;
};
//BUSINESS

export const apiGetAllServiceSalesBusiness = async (
  limit,
  padre,
  hijo,
  plate
) => {
  const url = urlEndPoint + '/api/v3/maintenance/service/business/all/' + limit;
  let search = {
    padre: padre,
    hijo: hijo,
    plate: plate,
  };
  const json = await callApi(url, 'POST', getToken(), search);
  return json;
};


export const apiGetMe = async () => {
  const url = `${urlEndPoint}/api/v3/users/me`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiGetBusinessAll = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/business/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const createBusiness = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/business`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const editCreditBusiness = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/credit/business`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};



export const editBusiness = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/business`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const deleteBusiness = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/business/del`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const deleteUserBusiness = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/business/user`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const apiGetUserBusinessAll = async (id) => {
  const url = `${urlEndPoint}/api/v3/users/business/user/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const createUserBusiness = async (body, id) => {
  const url = `${urlEndPoint}/api/v3/users/business/user/${id}`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

//USERS
export const apiGetUserAll = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/all/${init}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetUserStoreAll = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/store/all/${init}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiGetStoreContact = async (init) => {
  const url = `${urlEndPoint}/api/v3/store/contact`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const createUser = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/create`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const createUserStore = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/store/create`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const removeUserStore = async (userId) => {
  const url = `${urlEndPoint}/api/v3/users/store/remove/${userId}`;
  const json = await callApi(url, 'PUT', getToken(), null);
  return json;
};

export const apiEditUser = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/edit`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const apiGetUserAllFilter = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/search/all`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetUserStoreAllFilter = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/store/search/all`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetUserAllVendor = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/vendor/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetUserAllAsesor = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/adviser/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetUserAllAsesorAutoAsigner = async (init) => {
  const url = `${urlEndPoint}/api/v3/users/adviser/autoasigner`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiPostUserAllAsesorAutoAsigner = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/adviser/autoasigner`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};





export const apiGetUserEmail = async (email, type) => {
  const url = `${urlEndPoint}/api/v3/users/search/email`;
  let body = { email: email, type: type };
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetReportStatUser = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/user/dashboard`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetCalendarAll = async (y, m) => {
  const url = `${urlEndPoint}/api/v3/report/calendar/${y}/${m}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//TALLERES
export const apiGetAllStore = async () => {
  const url = `${urlEndPoint}/api/v3/store`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllStoreProvider = async () => {
  const url = `${urlEndPoint}/api/v3/store/provider`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllStoreProviderStore = async (idStore, brandID) => {
  const url = `${urlEndPoint}/api/v3/store/provider/search/${idStore}/${brandID}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatStoreRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/store/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllStoreMechannic = async () => {
  const url = `${urlEndPoint}/api/v3/store/people`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllHolidays = async () => {
  const url = `${urlEndPoint}/api/v3/store/holiday`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//SERVICIOS
export const apiGetAllService = async () => {
  const url = `${urlEndPoint}/api/v3/services`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiDelService = async (idService) => {
  const url = `${urlEndPoint}/api/v3/services/service/${idService}`;
  const json = await callApi(url, 'DELETE', getToken(), null);
  return json;
};

export const apiDelCupon = async (body) => {
  const url = `${urlEndPoint}/api/v3/discount`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const apiCreateCupon = async (body) => {
  const url = `${urlEndPoint}/api/v3/discount`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetServiceId = async (idService) => {
  const url = `${urlEndPoint}/api/v3/services/service/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetTaskService = async (idService) => {
  const url = `${urlEndPoint}/api/v3/services/task/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetBrandervice = async (idService) => {
  const url = `${urlEndPoint}/api/v3/services/brand/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStoreervice = async (idService) => {
  const url = `${urlEndPoint}/api/v3/services/store/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiPutUpdateService = async (idService, body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/data/${idService}`;

  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const apinewTask = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/new/task`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


export const apideleteTask = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/new/task`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const deleteStoreService = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/service/store`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const apiGetAllCategoryService = async () => {
  const url = `${urlEndPoint}/api/v3/services/category`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/service/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceCategoryRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/category/service/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//VEHICULOS
export const apiGetVehicle = async (init) => {
  const url = `${urlEndPoint}/api/v3/vehicle/all/${init}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllVehicle = async () => {
  const url = `${urlEndPoint}/api/v3/vehicle/allcar`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiGetModel = async (init) => {
  const url = `${urlEndPoint}/api/v3/vehicle/model`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatVehicleRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/vehicle/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetSearchPlate = async (plate) => {
  const url = `${urlEndPoint}/api/v3/vehicle/search`;
  let body = { plate: plate };
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiCreateVehicle = async (body) => {
  const url = `${urlEndPoint}/api/v3/vehicle/create`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

//TRANSSACTION
export const apiGetAllTransaction = async (init) => {
  const url = `${urlEndPoint}/api/v3/transaction/limit/${init}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetTransactionID = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/transaction/limit/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//STATUS

export const apiGetStatusMaintence = async () => {
  const url = `${urlEndPoint}/api/v3/states`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

// CUPONES

export const apiGetAllCupones = async () => {
  const url = `${urlEndPoint}/api/v3/discount/store`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//REPORT

export const apiGetOperationReport = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/operation`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetVentReport = async (m, y, t) => {
  const url = `${urlEndPoint}/api/v3/report/stat/vent/${m}/${y}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//KAUFMANN

export const apiGetTokenTuPago = async (idMaintenance) => {
  const url = `${urlEndPoint}/api/v3/transaction/kaufman/tupago/1/${idMaintenance}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetTokenTuPagoData = async (idMaintenanceService) => {
  const url = `${urlEndPoint}/api/v3/transaction/kaufman/tupago/2/${idMaintenanceService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetTokenTuPagoLink = async (
  maintenanceServiceId,
  urlKaufmann,
  token,
  xml,
  datos
) => {
  const url = `${urlEndPoint}/api/v3/transaction/kaufman/tupago/3/link/${maintenanceServiceId}`;
  let body = {
    urlKaufmann: urlKaufmann,
    token: token,
    xml: xml,
    datosclientes: datos,
  };
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetBitacora = async (ot) => {
  const url = `${urlEndPoint}/api/v3/maintenance/bitacora/${ot}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//ENCRIPTER URL
export const apiGetURLEncripter = async (text) => {
  const localToken = getTokenView(localStorage.getItem('session'));
  const data = await encryptedaes256cbcTextPlain(text, localToken).toString();
  return data;
};

/**
 * The function `apiDelPhotoReception` sends a DELETE request to the specified URL with the provided
 * body and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the necessary data for the API
 * request. It could include information such as the ID of the photo to be deleted or any other
 * relevant data required by the API endpoint.
 * @returns a JSON object.
 */
export const apiDelPhotoReception = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/reception/deleteImage`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

/**
 * The function `apiGetAllPhotoReception` makes an API call to retrieve all images associated with a
 * maintenance reception.
 * @param maintenanceId - The `maintenanceId` parameter is the unique identifier for a maintenance
 * record. It is used to retrieve all the photo reception images associated with that maintenance
 * record.
 * @returns a JSON object.
 */
export const apiGetAllPhotoReception = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/maintenance/reception/getAllImage/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


/**
 * Calls the API to get all photos for a given maintenance ID.
 * @param {string} maintenanceId - The ID of the maintenance for which to retrieve photos.
 * @returns {Promise} A promise that resolves to the JSON response containing all photos.
 */
export const apiGetAllPhotoOT = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/maintenance/gallery/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};



/**
 * The function `ApiUploadPhotoRecepcion` is an asynchronous function that uploads a photo to a
 * specific API endpoint and returns the response as JSON.
 * @param formData - The `formData` parameter is an object that contains the data to be sent in the
 * request body. It is typically used for sending files or binary data. In this case, it is being used
 * to send an image file for uploading.
 * @returns a JSON object.
 */
export const ApiUploadPhotoRecepcion = async (formData) => {
  const url = `${urlEndPoint}/api/v3/maintenance/reception/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

export const ApiAddReception = async (receptionData) => {
  const url = `${urlEndPoint}/api/v3/maintenance/reception/register`;
  const json = await callApi(url, 'POST', getToken(), receptionData);
  return json;
};

export const ApiAddBitacora = async (maintanceId, body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/bitacora/${maintanceId}`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const ApiCreateSAP = async (body) => {
  const url = `${urlEndPoint}/api/v3/sap`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const ApiCreateSAPTwo = async (body) => {
  const url = `${urlEndPoint}/api/v3/sap/create/user`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const ApiUploadPhotoOrder = async (formData) => {
  const url = `${urlEndPoint}/api/v3/maintenance/order/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

export const ApiUploadPhotoPay = async (formData) => {
  const url = `${urlEndPoint}/api/v3/maintenance/pay/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};





export const apiGetAllPhotoOrder = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/maintenance/order/getAllImage/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiDelPhotoOrder = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/order/deleteImage`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const ApiUploadPhotoAdditional = async (formData) => {
  const url = `${urlEndPoint}/api/v3/additional/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

export const apiCreateAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/create`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiDelAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/delAdditional`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const apiGetAdditionalAll = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/additional/getAdditional/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAdditionalImagen = async (maintenanceAdditionalId) => {
  const url = `${urlEndPoint}/api/v3/additional/getAdditionalImages/${maintenanceAdditionalId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiSendApprovedAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestApproval`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


export const apiSendNegotiate = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestNegotiate`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


export const apiSendApprovedAdditionalClient = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestApprovalClient`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiPayprovedAdditionalClient = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestpayApprovalClient`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};






export const apiPreviewprovedAdditionalClient = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestPreviewClient`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetFeeStore = async (storeId) => {
  const url = `${urlEndPoint}/api/v3/store/fee/${storeId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetInvoiceStatus = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/maintenance/invoice/getStatus/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetInvoice = async (limit) => {
  const url = `${urlEndPoint}/api/v3/transaction/invoices/${limit}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetPDFInvoice = async (body) => {
  const url = `${urlEndPoint}/api/v3/sap/pdf`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};



export const apiGetPDFFinal = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/report/final/pdf`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};



export const apiRestartInvoice = async (body) => {
  const url = `${urlEndPoint}/api/v3/sap/restart`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetAllGiros = async (body) => {
  const url = `${urlEndPoint}/api/v3/sap/giros`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllBrand = async (body) => {
  const url = `${urlEndPoint}/api/v3/provider/brand`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllModel = async (brand) => {
  const url = `${urlEndPoint}/api/v3/provider/model/${brand}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetRegion = async () => {
  const url = `${urlEndPoint}/api/v3/provider/region`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetComune = async (comune) => {
  const url = `${urlEndPoint}/api/v3/provider/comune/${comune}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetQuotes = async (predata) => {
  const url = `${urlEndPoint}/api/v3/provider/prevent`;
  const json = await callApi(url, 'POST', getToken(), predata);
  return json;
};

export const apiGetAllAdditional = async (type) => {
  const url = `${urlEndPoint}/api/v3/additional/getall/${type}`;

  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiCreatePrice = async (data) => {
  const url = `${urlEndPoint}/api/v3/provider/createPrice`;
  const json = await callApi(url, 'POST', getToken(), data);
  return json;
};

export const apiGetAllMessage = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/get/sendmessage`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


export const apiGetAllStepTransport = async (idBooking) => {
  const url = `${urlEndPoint}/api/v3/maintenance/steptransport/${idBooking}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};




export const apiGetPDFAddicional = async (maintenanceId, group, feeStore) => {
  const url = `${urlEndPoint}/api/v3/additional/pdf/${maintenanceId}/${group}/${feeStore}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllDrivers = async () => {
  const url = `${urlEndPoint}/api/v3/store/drivers`;
  const json = await callApi(url, 'GET', getToken(), null, null);
  return json;
};

export const apiGetTranfers = async () => {
  const url = `${urlEndPoint}/api/v3/store/transfers`;
  const json = await callApi(url, 'GET', getToken(), null, null);
  return json;
};

export const apiGetAllBrands = async () => {
  const url = `${urlEndPoint}/api/v3/brands`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiCreateBrand = async (body) => {
  const url = `${urlEndPoint}/api/v3/brands`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiUpdateBrandStatus = async (brandId, isActive) => {
  try {
    const url = `${urlEndPoint}/api/v3/brands/${brandId}/status`;
    const response = await callApi(url, 'PATCH', getToken(), { isActive });

    return response;
  } catch (error) {
    console.error("Error updating brand status:", error);
    throw error;
  }
};

export const apiGetBrandById = async (brandId) => {
  try {
    const url = `${urlEndPoint}/api/v3/brands/${brandId}`;
    const response = await callApi(url, 'GET', getToken());
    return response;
  } catch (error) {
    console.error("Error fetching brand by ID:", error);
    throw error;
  }
};

export const apiUpdateBrand = async (brandId, data) => {
  try {
    const url = `${urlEndPoint}/api/v3/brands/edit/${brandId}`;
    const response = await callApi(url, 'PUT', getToken(), data);
    return response;
  } catch (error) {
    console.error("Error updating brand:", error);
    throw error;
  }
};


export const apiGetBrandSearch = async (body) => {
  try {
    const url = `${urlEndPoint}/api/v3/brands/search/all`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  } catch (error) {
    console.error("Error searching brands:", error);
    throw error;
  }

};